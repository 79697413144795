import React, { useState, createRef, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Container, Content } from "./styled.js"
import { Button } from "../common/button.js"

const ContactForm = () => {
  const [formState, setFormState] = useState({
    submitting: false,
    submitted: false,
    error: false,
  })

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    function: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    company: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    message: Yup.string()
      .required("Required")
      .min(10, "Too Short!"),
    topic: Yup.string().required("Required"),
  })

  return (
    <Container>
      <Content style={{ maxWidth: "650px" }}>
        {formState.submitted ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "2em",
              paddingBottom: "5rem",
            }}
          >
            {formState.error ? (
              `It looks like something went wrong (code error: ${formState.error}). Please reload the page and  try again...`
            ) : (
              <p>
                Thank you, <br />
                we'll get in touch shortly!
              </p>
            )}
            <div style={{ fontSize: ".5em" }}>
              <a
                href="#"
                onMouseDown={() => {
                  setFormState({ submitted: false, error: false })
                }}
              >
                Send another message
              </a>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              function: "",
              email: "",
              company: "",
              message: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              if (!formState.submitting) {
                // same shape as initial values
                console.log(values)
                // setFormState({ submitting: true })
                fetch("/.netlify/functions/send-contact-email", {
                  method: "POST",
                  body: JSON.stringify(values),
                }).then(res => {
                  console.log("RES", res)
                  if (res.status !== 200) {
                    setFormState({ submitted: true, error: res.status })
                  } else {
                    setFormState({ submitting: false, submitted: true })
                  }
                })
              }
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <label htmlFor="firstName" style={{ display: "block" }}>
                  First name
                </label>
                <Field
                  className="contact-field"
                  name="firstName"
                  style={{
                    border:
                      touched.firstName && errors?.firstName
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <label htmlFor="lastName" style={{ display: "block" }}>
                  Last name
                </label>
                <Field
                  className="contact-field"
                  name="lastName"
                  style={{
                    border:
                      touched.lastName && errors?.lastName
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <label htmlFor="email" style={{ display: "block" }}>
                  Email
                </label>
                <Field
                  className="contact-field"
                  name="email"
                  type="email"
                  style={{
                    border:
                      touched.email && errors.email
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <label htmlFor="email" style={{ display: "block" }}>
                  Company
                </label>
                <Field
                  className="contact-field"
                  name="company"
                  style={{
                    border:
                      touched.company && errors.company
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <label htmlFor="function" style={{ display: "block" }}>
                  Job function
                </label>
                <Field
                  className="contact-field"
                  name="function"
                  style={{
                    border:
                      touched.function && errors.function
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />

                <label htmlFor="message" style={{ display: "block" }}>
                  Your message
                </label>
                <Field
                  className="contact-field"
                  as="select"
                  name="topic"
                  style={{
                    border:
                      touched.topic && errors.topic
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                >
                  <option value="" label="Select a topic">
                    Select a topic
                  </option>
                  <option value="general" label="General" />
                  <option value="issue" label="Technical issue" />
                  <option
                    value="deals_and_partnership"
                    label="Deals & Partnership"
                  />

                  <option
                    value="discount"
                    label="Educational or NGO discount "
                  />
                </Field>
                <Field
                  className="contact-field"
                  name="message"
                  rows="5"
                  as="textarea"
                  style={{
                    border:
                      touched.message && errors.message
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <Button onMouseDown={handleSubmit}>
                  {formState.submitting ? "Sending..." : "Submit"}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Content>
    </Container>
  )
}

export default ContactForm
