import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  padding: ${props => props.styleType === 'pricing' ? '4vh' : '0px'} 5vw ${props => props.styleType === 'pricing' ? '20vh' : '50px'};

  ${mq('l')} {
    min-height: 'none';
    padding: 45px 35px ${props => props.styleType === 'pricing' ? '20vh' : '50px'};
  }

  ${mq('s')} {
    padding: 45px 20px 50px;
  }
`;

export const Content = styled.div`
  width: 100%;

  .form-wrapper {
    max-width: 100%;
    width: 850px;
    margin: 0 auto;

    fieldset {
      max-width: 100%;

      > div {
        padding-left: 30px;
        padding-right: 30px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        ${mq('m')} {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

  

    .hbspt-form {

      .field {
        ${screenSize('margin-bottom', 30)}

        label {
          display: block;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          color: ${variables.colors.backgroundDark};

          ${fs(18)}
          ${screenSize('margin-bottom', 10)}
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        textarea {
          width: 100%;
          border: 1px solid ${variables.colors.greyLight};
          border-radius: 8px;
          ${fs(16)}

          ${screenSize('height', 40)}
          ${screenSize('line-height', 40)}
          padding-left: 10px;
          padding-right: 10px;
        }

        textarea {
          min-height: 120px;
          ${fs(16)}
          padding-left: 10px;
          padding-right: 10px;
        }

        select {
          width: 100%;
          border: 1px solid ${variables.colors.greyLight};
          border-radius: 8px;
          padding-left: 10px;
          padding-right: 10px;

          ${screenSize('height', 40)}
          ${screenSize('line-height', 40)}
          ${fs(16)}
        }

        
      }

      

    }

    input[type="submit"] {
          position: relative;
          z-index: 1;
          display: inline-flex;
          justify-content: center;
          padding: 0;
          color: ${variables.colors.white};
          background: ${variables.colors.red};
          font-family: 'Bossa', sans-serif;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;
          overflow: hidden;
          box-shadow: 0px 9px 90px rgba(7, 13, 28, 0.25);
          border: 0;

          ${fs(16)}

          ${screenSize('height', 62)}
          ${screenSize('line-height', 60)}
          ${screenSize('border-radius', 62)}
          ${screenSize('padding-left', 35)}
          ${screenSize('padding-right', 35)}

          transition: all .3s ease-in;

          &:hover {
            background: ${variables.colors.blue};
          }
        }

  .hs-error-msgs {
        list-style-type: none;
        padding: 5px 0 0;
        margin: 0;
        label.hs-error-msg {
          ${fs(14)}
          color: ${variables.colors.red};
        }
      }
  }

`;