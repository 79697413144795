import React, { useState, useEffect, useRef } from "react"
import bodymovin from "lottie-web"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"
import ContentHero from "../components/content-hero"

import ContactForm from "../components/contact-form"

const ContactPage = props => {
  let animationBackground = useRef()

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  useEffect(() => {
    if (
      animationBackground &&
      animationBackground.current &&
      !animationBackground.current.loaded
    ) {
      animationBackground.current.loaded = true
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: animationBackground.current,
        animType: "svg",
        autoplay: true,
        loop: false,
        path: "/animations/Content-shape-left.json",
      })
    }
  }, [currentBreakPoint])

  return (
    <Layout
      socialDark={true}
      currentPage="contact"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Contact" />

      <Section>
        <div
          style={{
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "26%",
            height: "39%",
            top: currentBreakPoint.type === "mobile" ? "250px" : "190px",
            right: "0",
          }}
        >
          <div>
            <div
              className="animationBackground"
              ref={animationBackground}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <ContentHero title="Contact us" text="We’re happy to talk to you." />
      </Section>

      <Section>
        <ContactForm />
      </Section>
    </Layout>
  )
}

export default ContactPage
